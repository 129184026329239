angular.module('ufcw')

.constant('EnvConfig', {
  "URL_API"			: "https://api-agenda.enfoqueweb.uy/",
  "URL_PAGOS"			: "https://payment.enfoqueweb.uy/",
  "URL_PAGOSCANCHA"	: "https://paymentv2.enfoqueweb.uy/",
  "URL_IMAGES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/enfoque/",
  "URL_FILES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/enfoque/",
  "COMPANY_ID"  : "1",
  "SIGNIN_ENABLED":true,
  "RESERVATION_ENABLED":false,
  "footer_app_name":"ENFOQUE",
  "footer_email":"redes@enfoqueweb.uy",
  "footer_facebook":"https://www.facebook.com/centroenfoque",
  "footer_twitter":"https://twitter.com/centroenfoque",
  "footer_instagram":"https://www.instagram.com/centroenfoque",
  "footer_youtube":"https://www.youtube.com/channel/UCLYzsh_m2QeWOJ8UuRSUJYQ",
  "show_aviso_covid":false,
  "show_cuponeras":true,
  "show_reglas":false,
  "signup_nrosocio":false,
  "signup_dob":true,
  "projectname":"enfoque",
  "show_payment_expiration": false,
  "show_carnet_expiration": true,
  "useDefaultStyle":false,
  "show_canchas":true
});
