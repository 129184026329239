'use strict';

angular.module('ufcw')
  .controller('SigninController', function ($rootScope,$localstorage,Request,$log,$state,toaster,EnvConfig,$window,$translate) {
    
    $rootScope.headLander = false;
    var vm = this;
    vm.useRoutines = $localstorage.get('useRoutines');
    vm.errorMsg = null;
    $localstorage.clear();
    vm.showCanchas = EnvConfig.show_canchas;
    
    vm.signin = function(){

      if(EnvConfig.SIGNIN_ENABLED||vm.email==="gonzalo@squal.us"||vm.email==="juanto@squal.us"||vm.email==="licdiegocarballo@gmail.com"||vm.email==="fernandaespinosa93@gmail.com"||vm.email==="ignacioveigatdf@gmail.com"){
        var data = {user:vm.email,password:vm.password};

        Request.put('signin',false,data,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
            $localstorage.clear();
          }else{
            $localstorage.set('token',response.token);
            $localstorage.setObject('user',response.description);
            var user_access = response.description.userAccess;
            $localstorage.setObject('dependents',response.dependents);
            $localstorage.setObject('user_files',response.user_files);
            $translate.use(response.description.lang);
            $localstorage.set('showMsg',(response.configuration.showInitialMsg===1)?true:false);
            $localstorage.setObject('config',response.configuration);
            $localstorage.setObject('messages',response.messages);
            $localstorage.setObject('alerts',response.alerts);
            $rootScope.messages = response.messages;
            if(user_access === 'CANCHA'){
              $state.go('homecanchas');
            }else{
              $state.go('home');
            }
              
            // });

          }
        });


      }else{
        toaster.pop('info',"", $translate.instant("signin.soon"));
      }




    };
  });
